import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  BASE_URL,
  IMAGE_SERVICE_HOST,
  IMAGE_SERVICE_HOST_LOCAL,
} from '../constants';
import { tap } from 'rxjs';
import { Router } from '@angular/router';
import { isLocalhost } from '../utils/helpers';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  sellerToken: string | null;

  headers: HttpHeaders = new HttpHeaders({});

  attributes: any = null;

  private profile: any | null = {};

  get isLoggedIn() {
    const sellerToken = localStorage.getItem('seller_token');
    if (
      sellerToken !== undefined &&
      sellerToken !== null &&
      sellerToken !== ''
    ) {
      return sellerToken;
    }
    return false;
  }

  get localStorageProfile() {
    const profile = localStorage.getItem('profile');
    if (profile !== undefined && profile !== null && profile !== '') {
      return JSON.parse(profile);
    }
    return false;
  }

  constructor(private http: HttpClient, private router: Router) {
    this.sellerToken = localStorage.getItem('seller_token');
    if (this.sellerToken !== null) {
      this.headers.set('Authorization', `Bearer ${this.sellerToken}`);
    }
    if (this.localStorageProfile) {
      this.profile = this.localStorageProfile;
    }

    this.getAttributeTypes().subscribe({
      next: (response: any | any[]) => {
        this.attributes = response;
      },
    });
  }

  private get(endpoint: string, queryParams?: any) {
    return this.http.get(`${BASE_URL}/V1/${endpoint}`, {
      headers: this.headers,
      params: queryParams,
      // withCredentials: true,
    });
  }

  private post(endpoint: string, queryParams?: any, data?: any) {
    return this.http.post(`${BASE_URL}/V1/${endpoint}`, data, {
      headers: this.headers,
      params: queryParams,
      // withCredentials: true,
    });
  }

  init = () => {
    if (this.isLoggedIn) {
      this.getCurrentUserProfile().subscribe(
        (resp) => {
          localStorage.setItem('profile', JSON.stringify(resp));
          this.profile = resp;
        },
        (error) => {
          if (error.status === 401) {
            this.logout();
            this.router.navigate(['/login']);
          }
        }
      );
    }
  };

  login = (email: string, password: string) => {
    return this.http
      .post(
        `${BASE_URL}/V1/integration/customer/token`,
        {
          username: email.trim(),
          password: password.trim(),
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        }
      )
      .pipe(
        tap((resp) => {
          if (resp && typeof resp === 'string') {
            localStorage.setItem('seller_token', resp);
            this.sellerToken = resp;
            this.headers.set('Authorization', `Bearer ${resp}`);
            this.init();
          }
        })
      );
  };

  logout() {
    this.profile = undefined;
    this.sellerToken = null;
    localStorage.removeItem('seller_token');
    localStorage.removeItem('profile');
  }

  register = (data: {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
  }) => {
    const { firstname, lastname, email, password } = data;
    return this.http
      .post(
        `${BASE_URL}/V1/customers`,
        {
          customer: {
            email,
            firstname,
            lastname,
            website_id: 1,
            group_id: 1,
            customAttributes: [
              {
                attribute_code: 'role_id',
                value: '6',
              },
            ],
          },
          password,
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        }
      )
      .pipe(
        tap((resp) => {
          if (resp && typeof resp === 'string') {
            localStorage.setItem('seller_token', resp);
            this.headers.set('Authorization', `Bearer ${resp}`);
          }
        })
      );
  };

  getCurrentUserProfile = () => {
    return this.http.get(`${BASE_URL}/default/V1/customers/me`, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.sellerToken,
      }),
    });
  };

  getCategories = () => {
    return this.get('categories');
  };

  getProductsForCategory = (categoryId: number) => {
    return this.get('products', {
      'searchCriteria[filter_groups][0][filters][0][field]': 'category_id',
      'searchCriteria[filter_groups][0][filters][0][value]': categoryId,
    });
  };

  getProductsById = (productId: string) => {
    return this.get(`products/${productId}`);
  };

  search = (params: any) => {
    return Object.keys(params).length === 0
      ? this.get('products', { searchCriteria: {} })
      : this.get('products', params);
  };

  getMyListings = () => {
    return this.get('products', {
      'searchCriteria[filter_groups][0][filters][0][field]': 'user_id',
      'searchCriteria[filter_groups][0][filters][0][condition_type]': 'eq',
      'searchCriteria[filter_groups][0][filters][0][value]': this.profile.id,
    });
  };

  getProfile = () => {
    return this.profile;
  };

  getAttributeTypes = () => {
    return this.http.get(
      `${BASE_URL}/default/V1/products/attribute-sets/16/attributes`
    );
  };

  uploadImage = (projectGuid: string, imageType: string, file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(
      `${isLocalhost() ? IMAGE_SERVICE_HOST_LOCAL : IMAGE_SERVICE_HOST
      }/upload-image/${projectGuid}/${imageType}`,
      formData
    );
  };
}
