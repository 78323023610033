import { ɵDEFAULT_LOCALE_ID } from '@angular/core';
import { formatNumber } from '@angular/common';
import { PRICE_RANGE_STEPS } from '../constants';
import { DropDownOptions } from '../models/common.models';

export const isNullOrUndefinedOrEmpty = (value: any): boolean => {
  return value === null || value === '' || typeof value === 'undefined';
};

export const isLocalhost = () => {
  return window.location.host === 'localhost:4200';
};

export const enumToText = (
  type: any,
  isDropdown?: boolean
): DropDownOptions[] => {
  return Object.values(type).map((value, index) => {
    return {
      text: isDropdown && value === '' ? '-- Select --' : value,
      value,
      id: index,
    } as DropDownOptions;
  });
};

export function castTo<T>(): (row: any) => T {
  return (row) => row as T;
}

export function getPriceRangeValueIdx(value: number) {
  const idx = PRICE_RANGE_STEPS.indexOf(value);
  return idx > -1 ? idx : 0;
}

export function translatePrice(value: number): string {
  if (value === 0) {
    return `€${formatNumber(0, ɵDEFAULT_LOCALE_ID, '1.0-0')}`;
  } else if (PRICE_RANGE_STEPS[value] < 1000000) {
    return `€${formatNumber(
      PRICE_RANGE_STEPS[value] / 1000,
      ɵDEFAULT_LOCALE_ID,
      '1.0-0'
    )}k`;
  } else if (PRICE_RANGE_STEPS[value] < 1000000000) {
    return `€${formatNumber(
      PRICE_RANGE_STEPS[value] / 1000000,
      ɵDEFAULT_LOCALE_ID,
      '1.0-0'
    )}mn`;
  } else {
    return `€${formatNumber(
      PRICE_RANGE_STEPS[value] / 1000000000,
      ɵDEFAULT_LOCALE_ID,
      '1.0-0'
    )}bn`;
  }
}
